<template>
	<router-view v-if="!this.isAuthenticated" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "AuthLayout",
	mounted() {
		if (this.isAuthenticated) {
			this.$router.push({ name: "dashboard" });
		}
	},
	computed: {
		...mapGetters(["isAuthenticated"])
	}
};
</script>
